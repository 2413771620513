import React, { FC, useContext } from 'react'
import Img3dUprightMoving, { configOptionsImg3dUpright } from './Img3dUprightMoving'
import { NotInViewContext } from './AnimateScrollIntoViewport'

import imgPerson1 from "../../images/persons/person1-200x200.jpg"
import imgPerson2 from "../../images/persons/person2-160x200.jpg"
import imgPerson3 from "../../images/persons/person3-177x200.jpg"
import imgPerson4 from "../../images/persons/person4-155x200.jpg"
import imgPerson5 from "../../images/persons/person5-146x200.jpg"
import imgPerson6 from "../../images/persons/person10.jpg"
import imgPerson7 from "../../images/persons/person7-200x195.jpg"
import imgPerson8 from "../../images/persons/person8-200x200.jpg"

interface UprightAvatarsMovingProps { }

const UprightAvatarsMoving: FC<UprightAvatarsMovingProps> = () => {
    const { notInView, isCountedTwice } = useContext(NotInViewContext)

    const styleKeyframesIfNotInView = (notInView && !isCountedTwice) ? { animKeyframes: "unset" } : {}
    return (
        <div>
            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsAvatar,
                    ...styleKeyframesIfNotInView,
                    img: imgPerson1,
                    heightBase: 50,
                    widthBase: 50,
                    ratioHeightImg: 1, // 0.5,
                    ratioWidthImg: 1, // 0.5,
                    diameterRotating: 150,
                    animDelay: 12.2,
                    animDuration: 15,
                }}
                key="p1"
            />
            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsAvatar,
                    ...styleKeyframesIfNotInView,
                    img: imgPerson2,
                    heightBase: 40,
                    widthBase: 40,
                    ratioHeightImg: 0.75, // 0.5,
                    ratioWidthImg: 0.75, // 0.5,
                    diameterRotating: 100,
                    animDelay: 13.2,
                    animDuration: 16,
                }}
                key="p2"
            />
            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsAvatar,
                    ...styleKeyframesIfNotInView,
                    img: imgPerson3,
                    heightBase: 50,
                    widthBase: 50,
                    ratioHeightImg: 1.0,
                    ratioWidthImg: 1.0,
                    diameterRotating: 120,
                    animDelay: 7.2,
                    animDuration: 16,
                }}
                key="p3"
            />
            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsAvatar,
                    ...styleKeyframesIfNotInView,
                    img: imgPerson4,
                    heightBase: 50,
                    widthBase: 50,

                }}
                key="p4"
            />
            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsAvatar,
                    ...styleKeyframesIfNotInView,
                    img: imgPerson5,
                    heightBase: 50,
                    widthBase: 50,
                    ratioHeightImg: 0.95,
                    ratioWidthImg: 0.95,
                    diameterRotating: 130,
                    animDelay: 8.2,
                    animDuration: 26,
                }}
                key="p5"
            />
            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsAvatar,
                    ...styleKeyframesIfNotInView,
                    img: imgPerson6,
                    heightBase: 50,
                    widthBase: 50,
                    ratioHeightImg: 0.95,
                    ratioWidthImg: 0.95,
                    diameterRotating: 110,
                    animDelay: 9.0,
                    animDuration: 14,
                }}
                key="p6"
            />
            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsAvatar,
                    ...styleKeyframesIfNotInView,
                    img: imgPerson7,
                    heightBase: 50,
                    widthBase: 50,
                    ratioHeightImg: 0.95,
                    ratioWidthImg: 0.95,
                    diameterRotating: 150,
                    animDelay: 10.0,
                    animDuration: 22,
                }}
                key="p7"
            />
            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsAvatar,
                    ...styleKeyframesIfNotInView,
                    img: imgPerson8,
                    heightBase: 50,
                    widthBase: 50,
                    ratioHeightImg: 0.95,
                    ratioWidthImg: 0.95,
                    diameterRotating: 130,
                    animDelay: 11.0,
                    animDuration: 17,
                }}
                key="p8"
            />
        </div>
    )
}

export default UprightAvatarsMoving